const setupFeedbackReportGeneratorModals = () => {
  $('.pu-remarks-item').click(function() {
    let existing_remarks = $('#pu_text_field').val()
    existing_remarks = `${existing_remarks}\n${$(this).text().trim()}`
    $('#pu_text_field').val(existing_remarks.trim())
    $('#pu_remarks_modal').modal('hide')
  });

  $('.doctor-remarks-item').click(function() {
    let existing_remarks = $('#doctors-text-field').val()
    existing_remarks = `${existing_remarks}\n${$(this).text().trim()}`
    $('#doctors-text-field').val(existing_remarks.trim())
    $('#doctors_remarks_modal').modal('hide')
  });
  
}

export default setupFeedbackReportGeneratorModals;