const toggleTableVisibility = (checkbox) => {
  const tableId = `${checkbox.id.split('|')[0]}|table`;
  const table = document.getElementById(tableId);

  if (checkbox.checked) {
    table.classList.remove('print-hidden');
  } else {
    table.classList.add('print-hidden');
  }
};

const handleCheckBox = () => {
  const printCheckboxes = document.querySelectorAll('.result-table-print-checkbox');

  printCheckboxes.forEach((checkbox) => {
    checkbox.addEventListener('click', (event) => {
      toggleTableVisibility(event.target);
    });
  });
};

const handleSelectAll = () => {
  const selectAllCheckbox = document.getElementById('select-all-checkbox');

  selectAllCheckbox.addEventListener('click', (event) => {
    const printCheckboxes = document.querySelectorAll('.result-table-print-checkbox');

    printCheckboxes.forEach((checkbox) => {
      checkbox.checked = event.target.checked;
      toggleTableVisibility(checkbox);
    });
  });
};

const isDecimal = (str) => {
  return !isNaN(str) && parseFloat(str) % 1 !== 0;
}
const roundToTwoDecimalPlacesHematology = () => {
  const hematologyTableId = 'HEMATOLOGY|table'
  const tdValues = document.getElementById(hematologyTableId).querySelectorAll(".td-value")
  console.log(tdValues)
  tdValues.forEach((td) => {
    const value = td.innerText
    if (isDecimal(value)) {
      td.innerText = parseFloat(value).toFixed(2)
    }
  })
}
const initResultForm = () => {
  if (window.location.pathname.includes('print_result_form')) {
    handleCheckBox();
    handleSelectAll();
    roundToTwoDecimalPlacesHematology()
  }
};

export default initResultForm;